import {Tooltip} from "@mui/material"

interface IconComponentProps {
    icon: string
    alt: string
    key?: any
    onClick?: () => void
    disabled?: boolean
}

const IconComponent = ({icon, alt, onClick, key, disabled}: IconComponentProps) => {
    return disabled !== undefined ? (
        <Tooltip sx={!disabled ? {opacity: "0.5", cursor: "not-allowed"} : {}} key={key} title={alt}>
            <img
                src={icon}
                alt={alt}
                onClick={!disabled ? undefined : onClick}
                style={!disabled ? {opacity: "0.5", cursor: "not-allowed"} : {cursor: "pointer"}}
            />
        </Tooltip>
    ) : (
        <Tooltip key={key} title={alt}>
            <img
                src={icon}
                alt={alt}
                onClick={disabled ? undefined : onClick}
                style={disabled ? {opacity: "0.5", cursor: "not-allowed"} : {cursor: "pointer"}}
            />
        </Tooltip>
    )
}

export default IconComponent
