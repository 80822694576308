import styles from "./Input.module.scss"
import IconComponent from "../IconComponent"
import FormLabel from "@mui/material/FormLabel"
import TextField from "@mui/material/TextField"
import FormControl from "@mui/material/FormControl"
import Box from "@mui/material/Box"
import InputAdornment from "@mui/material/InputAdornment"
import Typography from "@mui/material/Typography"

type InputProps = {
    label?: string
    placeholder?: string
    varient?: string
    type?: string
    value?: string | number
    [key: string]: any
    required?: boolean
    style?: any
    labelFontWeight?: any
    disabled?: boolean
    icon?: string
    tooltipText?: any
    iconClickHandler?: any
    autoFocus?: any
    editIcon?: any
    text?: string
}

const style = {margin: "12.5px 0px"}

const Input = (props: InputProps) => {
    return (
        <Box sx={{display: "flex", flexDirection: "column"}}>
            <FormControl sx={props.style ? props.style : style}>
                <FormLabel sx={{fontWeight: props?.labelFontWeight}} required={props.required}>
                    {props.label}
                </FormLabel>
                <TextField
                    autoFocus={props?.autoFocus}
                    multiline={props.name === "complaintDescription"}
                    InputProps={{
                        autoFocus: props?.autoFocus,
                        className: props.disabled ? styles.input__disabled : styles.input,
                        ...(props.disabled && !props?.editIcon
                            ? {
                                  endAdornment: props?.icon && (
                                      <InputAdornment position="end">
                                          <></>
                                      </InputAdornment>
                                  ),
                              }
                            : {
                                  endAdornment: props?.icon && (
                                      <InputAdornment position="end">
                                          <IconComponent
                                              icon={props?.icon}
                                              alt={props?.tooltipText}
                                              onClick={props?.iconClickHandler}
                                          />
                                      </InputAdornment>
                                  ),
                              }),
                    }}
                    FormHelperTextProps={{
                        classes: {
                            root: styles.input__error_message,
                        },
                    }}
                    // sx={{borderRadius: " 8px"}}
                    sx={{
                        ...(props.name === "complaintDescription" && {
                            "& .MuiOutlinedInput-root": {
                                height: "8em",
                                padding: 0,
                            },
                            "& .MuiOutlinedInput-input": {
                                boxSizing: "border-box",
                                height: "100% !important",
                                overflowY: "auto !important",
                                wordBreak: "break-all",
                            },
                        }),
                        // borderRadius: "8px",
                    }}
                    type={props.type}
                    placeholder={props.placeholder}
                    variant="outlined"
                    {...props}
                    label=""
                    disabled={props?.disabled}
                />
                {props?.text && <Typography className={styles.input_text}>{props?.text}</Typography>}
            </FormControl>
        </Box>
    )
}

export default Input
