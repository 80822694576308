import {MiddlewareAPI, isRejectedWithValue} from "@reduxjs/toolkit"
import {setError404Func} from "../components/Table/404/Error404Slice"
import {setAlertFunc} from "../components/Toaster/ToasterSlice"
import {setError500Func} from "../components/Table/500/Error500Slice"
import {setError401Func} from "../components/401/Error401Slice"
import {setSessionExpiredFunc} from "../components/SessionExpired/SessionExpiredSlice"

export const rtkQueryErrorLogger =
    ({dispatch}: MiddlewareAPI) =>
    (next: (arg0: any) => any) =>
    (action: any) => {
        // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
        if (isRejectedWithValue(action)) {
            console.log("We got a rejected action!", action.payload)
            // if (action.payload.status !== 409) {
            // dispatch(
            //     setAlertFunc({
            //         type: "error",
            //         message: action?.payload?.error
            //             ? action.payload.error
            //             : action?.payload?.data?.meta?.message
            //             ? action.payload.data.meta.message
            //             : action.payload.data.detail,
            //     })
            // )
            // }
            if (action.payload.status === 401) {
                dispatch(setError401Func({enable401: true}))
            } else {
                dispatch(setError401Func({enable401: false}))
            }
            if (action.payload.status === 403) {
                dispatch(setSessionExpiredFunc({enableSessionExpired: true}))
                dispatch(setError500Func({enableFiveHundred: false}))
            }
            if (action.payload.status === 404 || action.payload.status === 413) {
                dispatch(
                    setAlertFunc({
                        type: "error",
                        message: action?.payload?.error
                            ? action.payload.error
                            : action?.payload?.data?.meta?.message
                            ? action.payload.data.meta.message
                            : action.payload.data.detail,
                    })
                )
                dispatch(setError401Func({enable401: false}))
                dispatch(setError500Func({enableFiveHundred: false}))
                dispatch(setSessionExpiredFunc({enableSessionExpired: false}))
            }
            if (action.payload.status === 500) {
                if (action?.payload?.data.meta.type === "table") {
                    dispatch(setError500Func({enableFiveHundred: true}))
                }
                if (action.payload?.data?.data?.type === "table") {
                    dispatch(setError500Func({enableFiveHundred: true}))
                } else {
                    dispatch(
                        setAlertFunc({
                            type: "error",
                            message: action?.payload?.error
                                ? action.payload.error
                                : action?.payload?.data?.meta?.message
                                ? action.payload.data.meta.message
                                : action.payload.data.detail,
                        })
                    )
                }
            }
        }
        if (action?.payload?.length > 0) {
            dispatch(
                setError404Func({
                    enable404: false,
                })
            )
            dispatch(setError401Func({enable401: false}))
        }
        return next(action)
    }
